















































































































import { Vue, Component } from 'vue-property-decorator'
import AuthService from '@/services/auth_service'
import { Roles } from '@/models/user/roles'
import { GenericError } from '@/services/error_service'

@Component
export default class Register extends Vue {
  protected email = '';
  protected password = '';
  protected first = '';
  protected last = '';
  protected error = '';
  protected success = '';
  protected show1 = false;
  protected passwordRules = [
    (value: string) => !!value || 'Please enter a password.',
    (value: string) => !!value || 'Required',
    (value: string) => (value && /\d/.test(value)) || 'At least one digit',
    (value: string) =>
      (value && /[^A-Za-z0-9]/.test(value)) || 'At least one special character',
    (value: string) => (value && value.length > 7) || 'minimum 8 characters'
  ];

  protected authService = new AuthService();


  public async handleSubmit (): Promise<void> {
    try {
      await this.authService.register(this.first, this.last, this.email, this.password)
      this.$router.push('/success?success=true')
    } catch (err) {
      if (err instanceof GenericError) {
        GenericError.popup(err.message)
      } else {
        this.error = err.message
      }
    }
  }
}
